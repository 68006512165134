import axios from "axios";

const myAxios = async (url,method='post',data) => {
    try{
        
        let res = await axios({   
          method,
        //   url: `http://gl.vrfc.ru:89/BR/hs/br/rest_index/${url}`,
        //   url: `http://91e10ad2b632.sn.mynetname.net:85/BR/hs/br/rest_index/${url}`,
        url: `http://flower-trade.ru:85/BR/hs/br/rest_index/${url}`,
          //
          data,
          headers: {Accept: 'application/json','Content-Type': "text/plain"}
        });
        return res
    }
    catch{
        console.log('error')
        return false
    }
};
export default myAxios